<template>
  <div class="container py-3">
    <div class="vista-encabezado mb-3">
      <header class="col titulo h4-responsive text-lg-left">
        Reportes
      </header>
    </div>
    <div class="menu-reportes">
      <p
        v-for="(reporte, r) in menuReportes"
        :key="r"
        :class="['tarjeta-reporte', reporte.clase, {'disabled': reporte.deshabilitar}]"
        @click="cambiarComponente(reporte.ruta)"
      >
        {{ reporte.nombre }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reportes',
  data () {
    return {
      mostrarVendedores: false,
      menuReportes: [ // Lista de reportes del menú
        {
          nombre: 'Pedidos vs Despacho',
          clase: 'balance',
          ruta: 'PedidosvsDespachos',
          deshabilitar: false
        },
        {
          nombre: 'Pendiente vs Despachado',
          clase: 'clipboardList',
          ruta: 'PendienteVsDespachado',
          deshabilitar: false
        },
        {
          nombre: 'Pedido acumulado vs Despachado',
          clase: 'chartBar',
          ruta: 'PedidoAcumuladoVsDespachado',
          deshabilitar: false
        },
        {
          nombre: 'Kilos vendidos por vendedor',
          clase: 'truck-ramp-box',
          ruta: 'KilosVendidos',
          deshabilitar: false
        }
      ]
    }
  },
  methods: {
    cambiarComponente (nombreRuta) {
      this.$router.push({ name: nombreRuta })
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-reportes {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 576px) {
    flex-flow: row wrap;
  }

  .tarjeta-reporte {
    border: 1px solid $gris-tr-borde;
    border-radius: .5rem;
    cursor: pointer;
    color: $gris-muted;
    margin: .25rem;
    position: relative;
    padding: 1rem 5rem 3rem 1rem;
    transition-duration: .25s;
    transition-timing-function: ease;
    transition-property: border-color, color, font-weight;
    user-select: none;

    @media screen and (min-width: 576px) {
      width: 48%;
    }

    @media screen and (min-width: 1200px) {
      width: 32%;
    }

    &::after {
      bottom: 0;
      color: #d2d2d2;
      font-size: 3.5rem;
      right: .75rem;
      transform: rotate(17deg);
      transition-duration: .25s;
      transition-timing-function: ease;
      transition-property: transform, color;
      position: absolute;
    }
    &:hover {
      border-color: $primario;
      color: $primario;
      font-weight: 500;

      &::after {
        color: $primario;
        transform: rotate(0)
      }
    }

    &.balance::after {
      @include agregar-icono('\f24e')
    }

    &.clipboardList::after {
      @include agregar-icono('\f46d')
    }

    &.chartBar::after {
      @include agregar-icono('\f080')
    }
    &.truck-ramp-box::after {
      @include agregar-icono('\f4de')
    }
  }
}
</style>
